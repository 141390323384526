import { MatPaginatorIntl } from "@angular/material/paginator";
import moment from "moment";
import { StatusSplitItemEnum } from "src/app/core/proxy/enum/status-split-item-enum";
import { StatusSplitEnum } from "src/app/core/proxy/enum/status-split.enum";
import { TypeExternalPartnerEnum } from "src/app/core/proxy/enum/type-external-partner-enum";
import Swal from "sweetalert2";

export abstract class AbstractPage {
    constructor() { }

    public showConfirmationModal(text: string): Promise<boolean> {
        return Swal.fire({
            title: "Atenção",
            text: text,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "var(--kt-primary)",
            cancelButtonColor: "var(--kt-danger)",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
            reverseButtons: true
        }).then((result) => {
            return result.isConfirmed;
        });
    }

    public showSuccessModal(text: string): Promise<boolean> {
        return Swal.fire({
            title: "Sucesso!",
            text: text,
            icon: "success",
            confirmButtonColor: "var(--kt-primary)",
        }).then((result) => {
            return result.isConfirmed;
        });
    }

    public showErrorModal(text: string): Promise<boolean> {
        return Swal.fire({
            title: "Erro!",
            text: text,
            icon: "error",
            confirmButtonColor: "var(--kt-primary)",
        }).then((result) => {
            return result.isConfirmed;
        });
    }

    public formatDocument(value: string): string {
        if (value == null || value == undefined || value == '') {
            return '';
        }

        value = value.replace(/(\.|\/|\-)/g, '');
        if (value.length <= 11) {
            return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
        }
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
    }

    public formatAccountBank(accountBank: string) {
        let account = '';
        let count = accountBank.length;
        let penultimate = count - 2;
        for (let i = 0; i < count; i++) {
            account += accountBank[i];
            if (i == penultimate) {
                account += '-';
            }
        }
        return account;
    }

    public applyStatusColor(status: StatusSplitEnum) {
        switch (status) {
          case StatusSplitEnum.CREATED:
          case StatusSplitEnum.IN_PROCESS:
            return 'status blue';
          case StatusSplitEnum.PROCESS_COMPLETED:
            return 'status green';
          case StatusSplitEnum.AVAILABLE_TO_LIBERATION:
            return 'status yellow';
          case StatusSplitEnum.CANCELED:
          case StatusSplitEnum.PROCESS_COMPLETED_ERROR:
          case StatusSplitEnum.ERROR:
            return 'status red';
          case StatusSplitEnum.AWAITING_APPROVAL:
          case StatusSplitEnum.AVAILABLE:
          default:
            return 'status gray';
        }
      }
    
      public applyStatusText(status: StatusSplitEnum | StatusSplitItemEnum) {
        switch (status) {
          case StatusSplitEnum.CREATED:
            return 'Criado';
          case StatusSplitEnum.IN_PROCESS:
            return 'Em Processamento';
          case StatusSplitEnum.AVAILABLE:
            return 'Disponível';
          case StatusSplitEnum.PROCESS_COMPLETED:
            return 'Finalizado';
          case StatusSplitEnum.AVAILABLE_TO_LIBERATION:
            return 'Disponível para Liberação';
          case StatusSplitEnum.CANCELED:
            return 'Cancelado';
          case StatusSplitEnum.PROCESS_COMPLETED_ERROR:
            return 'Finalizado com Erro';
          case StatusSplitEnum.AWAITING_APPROVAL:
            return 'Aguardando Aprovação';
          case StatusSplitEnum.ERROR:
            return 'Erro';
          case StatusSplitItemEnum.PENDING:
            return 'Pendente';
          default:
            return status;
        }
      }
    
      public applyTypeActorText(typeActor: TypeExternalPartnerEnum) {
        switch (typeActor) {
          case TypeExternalPartnerEnum.AUCTIONNER:
            return 'Leiloeiro';
          case TypeExternalPartnerEnum.ORIGINATOR:
            return 'Originador';
          case TypeExternalPartnerEnum.PRINCIPAL_SELLET:
            return 'Vendedor';
          default:
            return typeActor;
        }
      }

      protected formatDataSearch(data: any) {
        if (data.startDate == null || data.startDate === '') {
          data.startDate = '';
        } else {
          data.startDate = moment(data.startDate).format('YYYY-MM-DD').toString();
        }
    
        if (data.endDate == null || data.endDate === '') {
          data.endDate = '';
        } else {
          data.endDate = moment(data.endDate).format('YYYY-MM-DD').toString();
        }
    
        if (data.value == null) {
          data.value = '';
        }
    
        if (data.startDate && data.endDate && data.startDate > data.endDate) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'A data de início não pode ser maior que a data final!',
          });
          return;
        }
    
        return data;
      }
    
      protected setupPaginatorLabels(paginator: MatPaginatorIntl) {
        paginator.itemsPerPageLabel = 'Items por página';
        paginator.previousPageLabel = 'Página Anterior';
        paginator.nextPageLabel = 'Próxima Página';
        paginator.getRangeLabel = (page: number, pageSize: number, length: number) => {
          if (length === 0 || pageSize === 0) {
            return `0 de ${length}`;
          }
    
          length = Math.max(length, 0);
          const startIndex = page * pageSize;
          const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    
          return `${startIndex + 1} - ${endIndex} de ${length}`;
        };
      }

      public calculateRangeOfDays(dateStart: Date, dateEnd: Date) {
        if (dateStart && dateEnd) {
          const start = moment(dateStart);
          const end = moment(dateEnd);
      
          const diffDays = end.diff(start, 'days');
      
          return diffDays;
        }
        return '-';
      }
}
