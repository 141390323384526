export enum StatusSplitItemEnum {
    CREATED = 'CREATED',
    PENDING = 'PENDING',
    AVAILABLE_TO_LIBERATION = 'AVAILABLE_TO_LIBERATION',
    AVAILABLE = 'AVAILABLE',
    IN_PROCESS = 'IN_PROCESS',
    PROCESS_COMPLETED = 'PROCESS_COMPLETED',
    PROCESS_COMPLETED_ERROR = 'PROCESS_COMPLETED_ERROR',
    ERROR = 'ERROR'
}
