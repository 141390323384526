export enum StatusSplitEnum {
  ALL = 'ALL',
  CREATED = 'CREATED',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  AVAILABLE_TO_LIBERATION = 'AVAILABLE_TO_LIBERATION',
  IN_PROCESS = 'IN_PROCESS',
  AVAILABLE = 'AVAILABLE',
  CANCELED = 'CANCELED',
  PROCESS_COMPLETED = 'PROCESS_COMPLETED',
  PROCESS_COMPLETED_ERROR = 'PROCESS_COMPLETED_ERROR',
  ERROR = 'ERROR'
}
